<template>
  <div class="container">
      <div v-show="test" class="test">
        <span>点击右上角选择在浏览器中打开</span>
		<img src="../assets/img/icon/youshangjiantou.png"/>
      </div>
  </div>
</template>

<script>
export default {
  data() {
      return{
          token:"",
		  test:false,
      }
  },
  activated(){},
  mounted() {
      // 判断是否是微信浏览器
      var is_wx=this.is_weixin()
      if(is_wx){
          this.test=true  // 是微信浏览器，则“点击右上角选择在浏览器中打开”文字显示
      }else{
          this.test=false // 不是微信浏览器，则“点击右上角选择在浏览器中打开”文字不显示
          this.download(); // 下载
      }
  },
  methods: {
    // 下载方法
    async download() {
		let fileUrl=this.$route.query.fileUrl;
		let fileName=this.$route.query.fileName;
			this.$http.downloadFile(fileUrl,fileName)
			.then(()=>{})
			.catch(()=>{})
		
	},
    // 判断是否是微信浏览器 
    is_weixin() {
        var ua = window.navigator.userAgent.toLowerCase(); 
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { 
            console.log('微信浏览器'); 
            return true;
        } else { 
            console.log("不是微信浏览器"); 
            return false;
        } 
    },
  }
}
</script>
<style scoped lang="scss">
	.test{
		background: rgba(0, 0, 0, 0.6);
		text-align: center;
		color: #fff;
		font-size: 15px;
		line-height: 50px;
		img{
			margin-left: 10px;
			width: 20px;
		}
	}
</style>
